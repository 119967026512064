<template>
  <div>
    <v-card :loading="!this.loading">
      <v-card-text>
        {{ traingins }}
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
export default {
  name: "AvailableStravaTrainings",
  props: ["pk", "date"],

  data: function () {
    return {
      loading: false,
      traingins: []
    }
  },


  mounted: async function () {
    await this.fetch();
  },


  watch: {
    "date": function (val) {
      this.fetch()
    }
  },

  methods: {
    fetch: async function () {
      this.loading = true
      let data = await this.$http.get(`/athlets/${this.pk}/strava_training/?date_from=${this.date}&date_to=${this.date}`)

      this.traingins = data.json()
      this.loading = false
    }
  }


}
</script>

<style scoped>

</style>
