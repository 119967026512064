<template>

  <v-card :loading="!months">
    <v-card-title>Training calendar
      <v-spacer/>
      <span v-if="athlet">
        Start date: {{ athlet.start_date }}
        </span>
    </v-card-title>

    <v-container fluid class="d-flex flex-row flex-wrap justify-content-start card-body no-gutters" v-if="months">
      <v-sheet elevation="1" tile v-for="month in months" v-bind:key="month.id"
               class="mr-3 mb-3 text-center no-select">
        <h6 class="float no-select">{{ month.month | moment("MMMM YYYY") }}</h6>
        <table class="calendar p-22 text-center">
          <thead>
          <tr>
            <td>Mon</td>
            <td>Tue</td>
            <td>Wed</td>
            <td>Thu</td>
            <td>Fri</td>
            <td>Sat</td>
            <td>Sun</td>
          </tr>
          </thead>

          <tr v-for="week in month.weeks" v-bind:key="week.id">
            <td v-for="day in week" v-bind:key="day.id">
              <div
                  :class="{selected: day.date == date,
                        muted: day.mute  ,
                        notraning: !day.mute && !day.id,
                      ok: day.status == 1,
                      today: (new Date(day.date)).setHours(0,0,0,0) == (new Date()).setHours(0,0,0,0),
                      auchtung: day.status == 2,
                      no_report: day.status == 3,
                      }"
                  style="display: table-cell"
                  class="no-select"
                  @click="selectDay(day)"
              >{{ day.date | moment("D") }}
              </div>
            </td>
          </tr>
        </table>
      </v-sheet>

    </v-container>
    <v-skeleton-loader v-else
                       ref="skeleton"
                       type="article"

                       class="mx-auto"
    />
  </v-card>

</template>


<script>
import {generateMockCalendar} from "@/utils/utils";

export default {
  props: ["athlet", "date"],

  data: function () {
    return {
      selectedDay: null,
      months: null
    };
  },

  mounted: async function () {
    await this.fetchData()
  },

  methods: {

    fetchData: async function () {
      this.months = null;
      this.months = (await this.$http.get(`athlets/${this.athlet.id}/reports/`)).data;
    },
    selectDay: function (day) {
      //emit event
      this.selectedDay = day;
      this.$emit("daySelected", day);
      // this.$router.
    }
  }
};
</script>


<style scoped lang="scss">

.no-select {
  user-select: none;
}

.calendar {
  margin: 0 auto;

  thead {
    font-size: 0.5em;
  }


  div {
    cursor: pointer;
    border: solid 1px gray;
    width: 16px;
    height: 16px;
    margin: 2px;

    font-size: 0.5em;
    font-weight: lighter;
    color: black;
    display: table-cell;

    vertical-align: middle;
    background: darkgray;
  }

  .auchtung {
    background: orange;

    &:hover {
      border: solid 1px black;
    }
  }

  .no_report {
    background: red;

    &:hover {
      border: solid 1px black;
    }
  }

  .ok {
    background: green;

    &:hover {
      border: solid 1px black;
    }
  }

  .muted {
    background: #ffffff;
    border: solid 1px lightgray;
    color: lightgray;
    cursor: default;
  }

  .notraning {
    background: lightgray;

    //cursor: default;
  }

  .today {
    border: solid 1px blue;
  }

  .selected {
    border: solid 1px black;
    font-weight: bolder;
    background: white;
  }
}
</style>
