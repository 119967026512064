<template>
    <v-card>
        <v-toolbar v-if="athlet">
            <v-toolbar-title>
                <span class="username font-weight-bold">{{athlet.user.email}}</span>&nbsp;
                <em class="plan" v-if="athlet.plan">({{athlet.plan.name}})</em>&nbsp;
                <span
                        class="status"
                        :class="{
        new: athlet.status == 0,
        active: athlet.status == 1,
        disabled: athlet.status == -1
        }"
                >{{status}}</span>
            </v-toolbar-title>
            <v-spacer/>
            <v-toolbar-items>
                <v-btn text :to="{name: 'progress', params: {id: athlet.id}}">View progress
                </v-btn>
                <v-btn text :to="{name: 'profile', params: {id: athlet.id}}" >View profile</v-btn>

            </v-toolbar-items>

        </v-toolbar>

        <v-list>
            <v-list-item v-for="message in messages" v-bind:key="message.id" v-bind:class="{
                    'text-right': message.from_trainer
                        }">
                <v-list-item-content>
                    <v-list-item-title><span v-if="message.from_trainer">You</span><span
                            v-else>{{athlet.user.email}}</span> <i>{{message.date| moment("MM/DD/YY HH:mm ")}}</i>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{message.message}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-form>
            <v-container>
                <v-row>

                    <v-col cols="12">
                        <v-text-field
                                v-model="message"
                                append-outer-icon="mdi-send"
                                filled

                                clearable
                                label="Message"
                                type="text"
                                @click:append-outer="sendMessage"
                        ></v-text-field>
                    </v-col>

                </v-row>
            </v-container>
        </v-form>

    </v-card>
</template>
<script>


    export default {
        name: 'chat',
        components: {},
        props: ['id',],

        data: function () {
            return {
                athlet: null,
                messages: [],
                message: "",
                timer: null
            }
        },

        mounted() {

            this.$http.get(`/athlets/${this.id}/`).then(
                (resp) => {
                    this.athlet = resp.data
                }
            );

            this.refresh();

            this.timer = setInterval(this.refresh, 1000 * 15)
        },


        methods: {
            sendMessage: function (sender) {
                this.$http.post(`/athlets/${this.id}/messages/`, {
                    message: this.message
                }).then(
                    (resp) => {
                        this.messages = resp.data
                    }
                );


            },

            cancelAutoUpdate() {
                clearInterval(this.timer)
            },

            refresh: function () {
                this.$http.get(`/athlets/${this.id}/messages/`).then(
                    (resp) => {
                        this.messages = resp.data
                    }
                );

            },

        },

        beforeDestroy() {
            clearInterval(this.timer)
        },

    }

</script>
