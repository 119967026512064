<template>
    <v-toolbar>
        <v-toolbar-title>
            <span class="username font-weight-bold">{{athlet.user.email}}</span>&nbsp;
            <em class="plan">({{athlet.plan.name}})</em>&nbsp;
            <span
                    class="status"
                    :class="{
        new: athlet.status == 0,
        active: athlet.status == 1,
        disabled: athlet.status == -1
        }"
            >{{status}}</span>
            <!--            <span>{{}}</span>-->
        </v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
            <v-btn text :to="{name: 'profile', params: {id: athlet.id}}">View profile
            </v-btn>
            <v-btn text :to="{name: 'chat', params: {id: athlet.id}}">View messages
            </v-btn>

        </v-toolbar-items>


        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item @click.stop="picker = true">
                    <v-list-item-title>Rebuild training plan</v-list-item-title>
                </v-list-item>
                <v-list-item @click="modify">
                    <v-list-item-title>Reapply adjustments</v-list-item-title>
                </v-list-item>
                <v-list-item @click="emulate_reports">
                    <v-list-item-title class="red--text">Emulate reports</v-list-item-title>
                </v-list-item>

            </v-list>
        </v-menu>


        <v-dialog v-model="dialog" hide-overlay persistent width="300">
            <v-card loading
            >
                <v-card-text class="text-center mt-4">
                    Processing changes..

                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog hide-overlay width="300" v-model="picker">

            <v-date-picker
                    title="Select new start date"
                    ref="picker"
                    v-model="athlet.start_date">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="picker = false">Cancel</v-btn>
                <v-btn text color="primary" @click="rebuild()">OK</v-btn>
            </v-date-picker>

        </v-dialog>

    </v-toolbar>


</template>

<script>
    export default {
        data: function () {
            return {
                picker: false,
                profile: {},
                dialog: false,
                start_date: this.athlet.start_date
            };
        },

        props: ["athlet"],

        computed: {
            status: function () {
                if (this.athlet.status != undefined) {
                    switch (this.athlet.status) {
                        case 1:
                            return "active";
                        case -1:
                            return "disabled";
                        case 0:
                            return "new";
                    }
                }

                return "n/a";
            },
        },

        methods: {
            rebuild: async function (date) {
                this.picker = false;
                this.dialog = true;
                (await this.$http.post(`/athlets/${this.athlet.id}/rebuild/`, {
                    start_date: this.athlet.start_date
                })).data;
                this.$emit("refresh", {});
                this.dialog = false
            },

            emulate_reports: async function () {
                this.dialog = true;
                (await this.$http.get(`/athlets/${this.athlet.id}/fix_reports/`)).data;
                this.$emit("refresh", {});
                this.dialog = false
            },

            modify: async function () {
                this.dialog = true;
                (await this.$http.get(`/athlets/${this.athlet.id}/modify/`)).data;
                this.$emit("refresh", {});
                this.dialog = false
            }
        }
    };
</script>
