<template>
    <v-card :loading="loading">
        <v-card-title>Presusubscribers list</v-card-title>
        <v-skeleton-loader v-if="loading"
                           ref="skeleton"
                           type="table"

                           class="mx-auto"
        />
        <v-simple-table v-else>

            <thead>
            <tr>
                <th>email</th>
                <th>add date</th>
                <th>enabled</th>
                <th>activation date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="profile in data" v-bind:key="profile.id">
                <td v-if="profile.updated"><a @click="query_and_redirect(profile.email)">{{profile.email}}</a></td>
                <td v-else>{{profile.email}}</td>
                <td>{{profile.date}}</td>
                <td>{{profile.enabled}}</td>
                <td>{{profile.updated}}</td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-card>
</template>

<script>
    import router from "@/router";

    export default {
        data: function () {
            return {
                data: [],
                loading: true,
            };
        },


        methods: {
            query_and_redirect: function (email) {
                this.$http.get("athlets/", {
                        params: {user__email: email}
                    }
                ).then(
                    response => {

                        this.$router.push({
                            name: "profile",
                            params: {
                                id: response.data[0].id
                            }
                        })
                    }
                )
            },
        },

        mounted: async function () {
            this.loading = true;
            this.$http.get("presubscribers/").then(
                response => {
                    this.data = response.data.results;
                    this.loading = false
                }
            );
        }
    }
    ;
</script>
