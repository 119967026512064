<script>
    import * as utils from "@/utils/utils";
    import "chartjs-plugin-annotation";
    import {Bar} from "vue-chartjs";

    export default {
        extends: Bar,

        methods: {
            color: function (param, type = 'bike', zone = "l1", zonesdata = {}) {


                let min = undefined;
                let max = undefined;
                let min_in_seconds = undefined;
                let max_in_seconds = undefined;

                var min_key = (type + "-" + zone + "-" + param + "-min").toLowerCase();
                var max_key = (type + "-" + zone + "-" + param + "-max").toLowerCase();
                if (param !== "pace") {
                    min = zonesdata[min_key] || 0;
                    max = zonesdata[max_key] || 99999;
                } else {
                    min = zonesdata[min_key] || 999999;
                    max = zonesdata[max_key] || 0;
                }

                //все pace как таковой не приходит. приходит скорость. надо  конвертнуть.
                if (param === "pace") {
                    try {
                        min_in_seconds = min.split(":")[0] * 60 + min.split(":")[1] * 1;
                        max_in_seconds = max.split(":")[0] * 60 + max.split(":")[1] * 1;
                    } catch (error) {
                        min_in_seconds = min;
                        max_in_seconds = max;
                    }
                    if (type === "swim") {
                        //для плаванья pace указывается на 100м
                        min = 100 / min_in_seconds;
                        max = 100 / max_in_seconds;
                    } else {
                        //для всех остальных на 1000м
                        min = 1000 / min_in_seconds;
                        max = 1000 / max_in_seconds;
                    }
                }

                if (min>max) {

                    [max, min] = [min, max];
                }

                return [min, max, function (context) {
                    let index = context.dataIndex;
                    let value = context.dataset.data[index];

                    if (value >= max) {
                        return "red";
                    }
                    if (value <= min) {
                        return "yellow";
                    }
                    return "green";
                }];
            }

        },

        props: {
            chartdata: {
                type: Object,
                default: function () {
                    return {"xxx":111}
                }
            },
            manner: {
                type: String,
                default: null,
            },
            zones: {
                type: Object,
                default: null
            },

            configure: {
                type: Function,
                default: null,
            }
        },

        data: () => ({
            min: undefined,
            max: undefined,
            strava_result: null,

            options: {
                legend: {
                    display: false
                },

                responsive: true,
                maintainAspectRatio: false,

                scales: {
                    xAxes: [
                        {
                            stacked: true
                        }
                    ],
                    yAxes: [
                        {
                            stacked: true
                        }
                    ]
                }
            }
        }),


        mounted() {
            if (this.configure) {
              let [labels, options, datasets] = this.configure(this, this.chartdata);
              this.renderChart(
                  {
                    labels: labels,
                    datasets: datasets
                  },
                  options
              );
            }


          this.renderChart(
              this.chartdata,
              this.options
          );


        }
    };
</script>
