import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import UserTraining from "@/views/UserTraining.vue"
import AthletList from "@/views/AthletsList.vue"
import Chat from "@/views/Chat.vue"
import Login from "@/views/Login.vue"
import Profile from "@/views/Profile.vue"
import Presubscribers from "@/views/Presubscribers.vue"
import Strava from "@/pages/Strava.vue"
import store from '@/store'

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'index',
        component: AthletList,
        props: true,

    },


    {
        path: '/login',
        name: 'login',
        component: Login
    },

    {
        path: "/athlets/:id/progress/:date?",
        component: UserTraining,
        name: 'progress',
        props: true
    },

    {
        path: "/athlets/:id/",
        component: Profile,
        name: 'profile',
        props: true
    },

    {
        path: "/athlets/:id/chat/",
        component: Chat,
        name: 'chat',
        props: true
    },

    {
        path: "/athlets/:pk/strava/",
        component: Strava,
        name: 'strava',
        props: true
    },


    {
        path: "/athlets/",
        component: AthletList,
        name: 'athlets'
    },

    {
        path: "/presubscribers/",
        component: Presubscribers,
        name: 'presubscribers'
    }


];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {


    if (!store.getters.user.loggedIn && to.name != 'login') {

        next('/login/')
    }
    next()

});

export default router
