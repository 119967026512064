import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'


import * as firebase from 'firebase/app';
import 'firebase/auth';


Vue.config.productionTip = false



let firebaseConfig = {
  apiKey: "AIzaSyALZZKey84dDWiE9GL3piNnrrHj_Pillqk",
  authDomain: "trigo-9f40e.firebaseapp.com",
  databaseURL: "https://trigo-9f40e.firebaseio.com",
  projectId: "trigo-9f40e",
  storageBucket: "trigo-9f40e.appspot.com",
  messagingSenderId: "640078757012",
  appId: "1:640078757012:web:2677f9420772c06e59c881",
  measurementId: "G-8BW8N0P0TB"
};



import Axios from 'axios'

Vue.prototype.$http = Axios;


firebase.initializeApp(firebaseConfig);


import { library } from '@fortawesome/fontawesome-svg-core'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vuetify from './plugins/vuetify';

library.add(faBell)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(require('vue-moment'));

firebase.auth().onAuthStateChanged(user => {

  store.dispatch("fetchUser", user);
 
  Axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
  Axios.interceptors.request.use(async (config) => {
    const tokenResponse = await firebase.auth().currentUser!.getIdToken();
    config.headers.Authorization = tokenResponse;
    store.commit('LOADING', true);
    return config;

  }, (error) => {
    store.commit('LOADING', false);
    return Promise.reject(error);
   
  });


  Axios.interceptors.response.use((response) => {
    store.commit('LOADING', false);
    return response;
  }, (error) => {
    store.commit('LOADING', false);
    return Promise.reject(error);
  });

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')


});


