<template>
  <v-card :loading="loading">

    <v-skeleton-loader v-if="loading"
                       ref="skeleton"
                       type="article"

                       class="mx-auto"
    />

    <Overview style="height:100%" v-else :chart-data="chartdata" :options="options" />
  </v-card>
</template>

<script>
import Overview from "@/components/charts/Overview";

export default {
  props: ["athlet"],

  components: {
    Overview
  },

  data: function(){return {
    loading: true,
    chartdata: null,
    options: {
      legend: {
        display: false
      },

      responsive: true,
      maintainAspectRatio: false,

      scales: {
        xAxes: [
          {
            display: false,
            ticks: {
              display: false //this will remove only the label
            }
          }
        ]
      },
      onClick: (event, item)=> {
        if (item[0]) {
        this.selectDay(item[0]._model.label)
        }
      }
  }}},

   methods: {
    selectDay: function(day) {

        this.$emit("dayselected", day);

    },
     fetchData: async  function(){
       this.loading =true;
       let stats = (await this.$http.get(`/athlets/${this.athlet.id}/overview/`)).data.days;

       var datasets = [
         {
           backgroundColor: stats.map((val, i) => {
             if (val.status == 1) {
               return "green";
             }

             if (val.status == 2) {
               return "orange";
             }

             if (val.status == 3) {
               return "red";
             }
           }),
           data: stats.map(val => val.value)
         }
       ];

       this.chartdata = {
         labels: stats.map((val, i) => val.date),
         datasets: datasets
       };

       this.loading = false;
     }
  },

  async mounted() {
    await  this.fetchData()

  }
};
</script>
