import Vue from 'vue'
import Vuex from 'vuex'
import set = Reflect.set;

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: {
            refCount: 0,
            isLoading: false,
        },
        user: {
            loggedIn: false,
            data: null
        },
        athlet: {
            pk: null
        },

        workout: {}

    },

    getters: {
        user(state) {
            return state.user
        },
        athlet(state) {
            return state.athlet
        },
        workout(state) {
            return state.workout
        }

    },

    mutations: {
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        },

        SET_ATHLET(state, athlet) {
            state.athlet = athlet
        },

        SET_WORKOUT(state, workout) {
            state.workout = workout
        },

        LOADING(state, isLoading) {

            if (isLoading) {
                state.loading.refCount++;
                state.loading.isLoading = true;
            } else if (state.loading.refCount > 0) {
                state.loading.refCount--;
                state.loading.isLoading = (state.loading.refCount > 0);
            }
        }

    },
    actions: {
        fetchUser({commit}, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user) {
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email
                });
            } else {
                commit("SET_USER", null);
            }
        },

        setAthlet({commit}, athlet) {
            commit("SET_ATHLET", athlet)
        },

        setWorkout({commit}, workout) {
            commit("SET_WORKOUT", workout)
        }
    }
});