<template>
  <v-card :loading="loading">
    <v-card-title>{{ workout.date }}</v-card-title>
    <v-container fluid>
      <v-row>
        <v-col>
          <WorkoutItem :workout="workout.swim" type="swim" @submit="submit" @clear="reset"
                       :report="workout.report">Swim
          </WorkoutItem>
        </v-col>
        <v-col>
          <WorkoutItem :workout="workout.bike" type="bike" @submit="submit" @clear="reset" :report="workout.report">
            Bike
          </WorkoutItem>
        </v-col>
        <v-col>
          <WorkoutItem :workout="workout.run" type="run" @submit="submit" @clear="reset" :report="workout.report">Run
          </WorkoutItem>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-chip-group class="col-12" v-if="workout.report">
            <v-chip  :color="toColor(workout.report.dream)">
              Sleep: {{ workout.report.dream }}
            </v-chip>
            <v-chip :color="toColor(workout.report.muscle)">Muscle:{{ workout.report.muscle }}</v-chip>
            <v-chip  :disabled="!workout.report.jetlag" :color="workout.report.jetlag? 'error': null">JETLAG</v-chip>
            <v-chip  :disabled="!workout.report.ilness" :color="workout.report.ilness? 'error': null">ILNESS</v-chip>
            <v-chip  :disabled="!workout.report.trauma" :color="workout.report.trauma? 'error': null">
              TRAUMA
            </v-chip>
            <v-chip :color="toColor(workout.report.pulse)">pulse: {{ workout.report.pulse }}</v-chip>
            <v-chip :color="toColor(workout.report.exhaustion)">exhaustion:
              {{ workout.report.exhaustion }}
            </v-chip>
          </v-chip-group>
          <p v-else>
            no report provided
          </p>


        </v-col>
      </v-row>


      <v-row>

          <AvailableStravaTrainings :pk="athlet.id" :date="date" />
      </v-row>

      <v-row>
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Debug info:
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <pre>{{ workout }}</pre>
                <pre>{{ athlet }}</pre>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

    </v-container>

  </v-card>
</template>

<script>


import WorkoutItem from "@/components/WorkoutItem";
import AvailableStravaTrainings from "@/components/AvailableStravaTrainings"

export default {
  props: ["date", "athlet"],

  components: {
    WorkoutItem,
    AvailableStravaTrainings
  },

  data: function () {
    return {
      loading: true,
      workout: {}
    };
  },

  watch: {
    date: async function (newVal, oldVal) {
      await this.fetchData();
    }
  },

  mounted: async function () {
    await this.fetchData();
  },

  methods: {


    toColor: function (val) {

      if (val>90) return "green";

      if (val < 80) return "error";

      return "orange"
    },

    submit: async function (event) {
      event.date = this.date;
      this.loading = true;

      try {
        await this.$http.post(
            `/athlets/${this.athlet.id}/workout/?date=${this.date}`,
            event
        )
      } catch (e) {
        //TODO
      }

      await this.fetchData()

      this.loading = false

    },

    reset: async function (event) {

      try {
        event.date = this.date;
        await this.$http.delete(
            `/athlets/${this.athlet.id}/workout/?date=${this.date}&type=${event.type}`
        )
      } catch (e) {
        //TODO
      }

      await this.fetchData()

      this.loading = false
    },

    fetchData: async function () {
      this.loading = true;

      try {

        this.workout = (await this.$http.get(
            `/athlets/${this.athlet.id}/workout/?date=${this.date}`
        )).data;
      } catch (error) {
        this.workout = {}
      }

      // await this.$store.dispatch("setWorkout", this.workout);
      this.loading = false;
    }
  }
};
</script>
