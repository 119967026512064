<template>

  <v-card :loading="data == null" class="mb-5">

    <v-card-title>Bike:</v-card-title>
    <v-card-subtitle>{{strava_result}}</v-card-subtitle>
    <v-skeleton-loader v-if="!data"
                       ref="skeleton"
                       type="article"

                       class="mx-auto"
    />

    <div v-else>
      <ChartBase
          :chartdata="data"
          :zones="zones"
          :configure="this.configure"
      />
    </div>
  </v-card>
</template>


<script>
import ChartBase from "./ChartBase";
import * as utils from "@/utils/utils";

export default {
  props: ['data', "zones",],

  components: {
    ChartBase,
  },

  data: () => ({
    loading: true,
    manner: "l1",
    strava_result: ""
  }),


  methods: {

    configure: function (self, data) {
      let yAxes = [];

      let xAxes = [
        {
          categoryPercentage: 1.1,
          barPercentage: 1.1,
          ticks: {
            // maxTicksLimit: 19,
            callback: v => {
              return (v / 1000).toFixed(1) + "km";
            }
          }
        }
      ];

      var primary = null;
      var secondary = null;
      let datasets = [];

      let min, max, colour;

      if (data["watts"]) {
        [min, max, colour] = self.color("power", 'bike', "l1", self.zones);
        this.strava_result =
            utils.meters_to_km(data["actual_distance"]) +
            "/" +
            utils.meters_to_km(data["expected_distance"]) +
            "km @" +
            data["weighted_average_watts"] +
            "Watts";
        primary = {
          chart: {
            type: "bar",
            label: "power",
            data: data["watts"]["data"],
            backgroundColor: colour,
            borderColor: colour,
            yAxisID: "axis-main",
            borderWidth: 0
          },
          axis: {
            id: "axis-main",
            type: "linear",

            ticks: {
              callback: v => {
                return v + "W";
              }
            }
          }
        };

        if (data["heartrate"]) {
          secondary = {
            chart: {
              type: "line",
              label: "heartrate",
              data: data["heartrate"]["data"],
              backgroundColor: "black",
              borderColor: "black",
              yAxisID: "axis-heartrate",
              fill: false,
              pointRadius: 0,
              options: {

              },

              borderWidth: 1.5
            },
            axis: {
              id: "axis-heartrate",
              type: "linear",
              position: "right",
              ticks: {
                callback: v => {
                  return v + "bpm";
                }
              }
            }
          };
        } else {
          secondary = {
            chart: {
              type: "line",
              label: "speed",
              data: data["velocity_smooth"]["data"],
              backgroundColor: "#000000",
              borderColor: "#000000",
              yAxisID: "axis-pace",
              pointRadius: 0,
              fill: false,
              borderWidth: 1.5
            },

            axis: {
              id: "axis-pace",
              type: "linear",
              position: "right",
              ticks: {
                callback: v => {
                  return v + " m/s";
                }
              }
            }
          };
        }
      } else if (data["heartrate"]) {
        [min, max, colour] = self.color("heartrate", 'bike', "l1", self.zones);

        self.strava_result =
            utils.meters_to_km(data["actual_distance"]) +
            "/" +
            utils.meters_to_km(data["expected_distance"]) +
            "km @ " +
            utils.seconds_to_minutes(utils.speed_to_pace(data["average_speed"])) +
            "km";
        primary = {
          chart: {
            type: "bar",
            label: "heartrate",
            data: data["heartrate"]["data"],
            backgroundColor: colour,
            borderColor: colour,
            yAxisID: "axis-main",
            borderWidth: 0
          },
          axis: {
            id: "axis-main",
            type: "linear",
            ticks: {
              callback: v => {
                return v + "bpm";
              }
            }
          }
        };
      }
      if (secondary) {
        datasets.push(secondary.chart);
        yAxes.push(secondary.axis);
      }

      if (primary) {
        datasets.push(primary.chart);
        yAxes.push(primary.axis);
      }

      let labels = data["distance"]["data"];
      let tooltips = {
        callbacks: {
          title: function (tooltipItem, data) {
            if (tooltipItem[0].xLabel > 1000) {
              return `${tooltipItem[0].xLabel.toPrecision(3) / 1000} km`;
            } else {

              return `${tooltipItem[0].xLabel.toFixed()} m`;

            }
          },
          label: function (tooltipItem, data) {
            if (data.datasets.length > 1 && tooltipItem.datasetIndex === 0) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += Math.round(tooltipItem.yLabel) + "bpm"
              return label;
            }

              return `${tooltipItem.yLabel.toFixed()} W`;

          }
        }
      };
      let options = {
        maintainAspectRatio: false,
        tooltips: tooltips,
        scales: {
          yAxes: yAxes,
          xAxes: xAxes,

          legend: {
            display: false
          }
        },

        legend: {
          display: false
        }
      };

      if (min || max) {
        options['annotation'] = {
          'annotations': [{
            drawTime: 'afterDraw', // overrides annotation.drawTime if set
            type: 'line',
            mode: 'horizontal',
            scaleID: 'axis-main',
            value: min,
            borderColor: 'yellow',
            borderWidth: 2,


          }, {
            drawTime: 'afterDraw', // overrides annotation.drawTime if set
            id: 'axis-pace',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'axis-main',
            value: max,
            borderColor: 'red',
            borderWidth: 2,

          }]

        }
      }


      return [labels, options, datasets]

    },
  }
}
</script>
