<template>

  <v-card :loading="data == null" class="mb-5">

    <v-card-title>Run:</v-card-title>
    <v-card-subtitle>{{strava_result}}</v-card-subtitle>

    <v-skeleton-loader v-if="!data"
                       ref="skeleton"
                       type="article"
                       class="mx-auto"
    />

    <div v-else>
      <ChartBase
          :chartdata="data"
          :zones="zones"
          :configure="this.configure"
      />
    </div>
  </v-card>
</template>


<script>
import ChartBase from "./ChartBase";
import * as utils from "@/utils/utils";

export default {
  props: ['data', "zones",],

  components: {
    ChartBase,
  },

  data: () => ({
    loading: true,
    manner: "l1",
    strava_result: ""

  }),


  methods: {

    configure: function (self, data) {

      let yAxes = [];

      let xAxes = [
        {
          categoryPercentage: 1.1,
          barPercentage: 1.1,
          ticks: {
            // maxTicksLimit: 19,
            callback: v => {
              return (v / 1000).toFixed(1) + "km";
            }
          }
        }
      ];

      let datasets = [];

      let min, max, colour;


      if (data['heartrate']) {

        datasets.push({
          type: "line",
          label: "heartrate",
          data: data['heartrate']['data'],
          backgroundColor: "#000000",
          borderColor: "#000000",
          yAxisID: "axis-heartrate",
          fill: false,
          pointRadius: 0,
          borderWidth: 1.5


        });

        yAxes.push({
          position: "right",
          id: "axis-heartrate", type: 'linear',
          ticks: {
            callback: (v) => {
              return v + "bpm";
            }
          }

        })
      }

      [min, max, colour] = self.color("pace", 'run', "l1", self.zones);


      datasets.push({
        type: "bar",
        label: "speed",
        data: data['velocity_smooth']['data'],
        backgroundColor: colour,
        borderColor: colour,
        yAxisID: "axis-main",
        borderWidth: 0

      });

      yAxes.push({
        id: "axis-main", type: 'linear', position: "left",
        ticks: {
          callback: (v) => {
            return utils.toMinutes(utils.speed_to_pace(v)) + " min/km";
          }
        }

      });


      let tooltips = {
        callbacks: {
          title: function (tooltipItem, data) {
            if (tooltipItem[0].xLabel > 1000 ) {
              return `${tooltipItem[0].xLabel.toPrecision(3) / 1000} km`;
            } else {

              return `${tooltipItem[0].xLabel.toFixed()} m`;

            }
          },
          label: function (tooltipItem, data) {


            if ((data.datasets.length > 1 && tooltipItem.datasetIndex === 1) || data.datasets.length == 1) {
              return "pace: " + utils.toMinutes(utils.speed_to_pace(tooltipItem.yLabel)) + ` min/km \n speed: ${tooltipItem.yLabel.toPrecision(2)} m/s`;

            }

            if (data.datasets.length() > 1 && tooltipItem.datasetIndex === 0) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += Math.round(tooltipItem.yLabel) + "bpm"
              return label;
            }


          }
        }
      };


      let labels = data["distance"]["data"];

      let options = {
        maintainAspectRatio: false,
        tooltips: tooltips,
        scales: {
          yAxes: yAxes,
          xAxes: xAxes,

          legend: {
            display: false
          }
        },

        legend: {
          display: false
        }
      };

      if (min && max) {

        options['annotation'] = {
          'annotations': [{
            drawTime: 'afterDraw', // overrides annotation.drawTime if set
            type: 'line',
            mode: 'horizontal',
            scaleID: 'axis-main',
            value: min,
            borderColor: 'yellow',
            borderWidth: 2,


          }, {
            drawTime: 'afterDraw', // overrides annotation.drawTime if set
            id: 'axis-pace',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'axis-main',
            value: max,
            borderColor: 'red',
            borderWidth: 2,

          }]

        }
      }


      return [labels, options, datasets]

    },

  }
}
</script>
