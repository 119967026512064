<template>
    <v-container>
      <v-row class="justify-center ">

        <v-card  class=" col-3">
            <v-card-title>3GO admin panel</v-card-title>

            <v-form>
                <v-text-field label="Login" v-model="form.email" type="text"></v-text-field>
                <v-text-field label="Password" v-model="form.password" type="password"></v-text-field>
            </v-form>
            <v-card-text v-if="error">{{error}}</v-card-text>

            <v-card-actions class="justify-end">
                <v-btn @click="submit">Login</v-btn>
            </v-card-actions>
        </v-card>

      </v-row>

    </v-container>
</template>

<script>
    import firebase from "firebase";

    export default {
        data() {
            return {
                form: {
                    email: "",
                    password: ""
                },
                error: null
            };
        },
        methods: {
            submit() {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(this.form.email, this.form.password)
                    .then(data => {
                        this.$router.replace({name: "athlets"});
                    })
                    .catch(err => {
                        this.error = err.message;
                    });
            }
        }
    };
</script>