import "@/colors"

export function generateMockCalendar() {
  function getState() {
    let i = Math.random();
    if (i >= 0.9) return "auchtung";
    if (i >= 0.3) return "ok";
    return "default";
  }

  let months = [];
  for (var i = 0; i < 11; i++) {
    var month = {
      weeks: []
    };
    var week = [];

    for (var j = 1; j <= 31; j++) {
      week.push({
        day: j,
        state: getState()
      });
      if (j % 7 == 0) {
        month.weeks.push(week);
        week = [];
      }
    }
    month.weeks.push(week);
    months.push(month);
  }

  return months;
}

export function meters_to_km(meters) {
  return (meters / 1000).toFixed(2);
}

export function ms_kmh(ms) {
  return Math.round((ms * 360) / 1000, 2);
}

export function seconds_to_minutes(seconds) {
  return (
    Math.floor(seconds / 60) +
    ":" +
    ("0" + Math.floor(seconds % 60)).slice(-2)
  );
}

export function speed_to_pace(speed_ms) {
  if (speed_ms == 0) {
    return 0;
  }
  return (1 / speed_ms) * 1000;
}

export function speed_to_pace_swim(speed_ms) {
  if (speed_ms == 0) {
    return 0;
  }

  return (1 / speed_ms) * 100;

}

export function toMinutes(value) {
  return Math.floor(value / 60) + ':' + ('0' + Math.floor(value % 60)).slice(-2);

}
