<template>
  <v-card style="height: 100%">
    <div class="v-card-progress" :class="type"/>
    <v-card-actions>
      <span class="text-h5 text-upper-first">{{ type }}</span>
      <v-spacer/>
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs"
                 :disabled="!editable"
                 v-on="on">
            <v-icon small>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Edit {{ type }} base</span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="formDistance"
                        label="Distance"
                        :rules="[rules.isFloat]"
                        placeholder="Enter required distance"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                        v-model="formZone"
                        :items="zones"
                        label="Zone"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                        v-model="formComment"
                        label = "Detailed description"
                        placeholder="Enter detailed description"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
                color="blue darken-1"
                text
                @click="clear()"
                :disabled="!(workout && workout.customized)"
            >
              Clean customization
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="submit()"

                :disabled="!formValid"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
    <v-card-subtitle v-if="workout"> {{ workout.distance }} km {{ workout.manner }}
    </v-card-subtitle>
    <v-card-text v-if="workout">
      <ul v-if="workout && !workout.customized">
        <li v-for="adj in workout.modifications" :key="adj">{{ adj }}</li>
      </ul>
      <h3 v-if="workout && workout.customized"><span v-if="formComment">{{formComment}}</span><span v-else>Customized by admin</span></h3>

    </v-card-text>
    <v-card-text v-if="!workout">
      N/A
    </v-card-text>

    <v-card-subtitle v-if="progress" class="d-flex flex-row">
      <v-progress-linear :value="progress" :color="color" style="height: 25px">
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>

      </v-progress-linear>
      <span class="ml-8 align-baseline" v-if="report.strava">manual</span>
      <span class="ml-8 align-baseline" v-else>manual</span>
    </v-card-subtitle>
  </v-card>
</template>

<script>

export default {
  name: "WorkoutItem",

  data: function () {

    let distance = this.workout ? this.workout.base_distance : 0.0;
    let formZone = this.workout ? this.workout.manner : "L1";
    let comment = this.workout ? this.workout.comment : "";
    return {
      dialog: false,
      zones: [
        "A1", "L1", "L2", "L3 (Fartlek)", "L4", "VO2max", "Max", "Brick", "Tech", "RP", "Hill"
      ],
      formDistance: distance,
      formZone: formZone,
      formComment: comment,
      rules: {
        isFloat(val) {
          return val > 0 && !isNaN(val)
        }
      }

    }
  },

  methods: {
    submit: function () {
      this.dialog = false;
      this.$emit("submit", {
        distance: this.formDistance,
        zone: this.formZone,
        type: this.type,
        comment:this.formComment,
      })
    },

    clear: function () {
      this.dialog = false;
      this.$emit("clear", {
        type: this.type
      })
    }
  },

  props: ["workout",
    "type",
    "report"],

  watch: {
    workout: function () {
      this.formDistance = this.workout ? this.workout.base_distance : 0.0;
      this.formZone = this.workout ? this.workout.manner : "L1";
      this.formComment = this.workout ? this.workout.comment: "";
    }
  },

  computed: {
    color: function () {
      if (this.progress >= 80) return "green"
      if (this.progress <= 80) return "orange"

      return "red";
    },
    formValid: function () {
      return this.formDistance > 0 && !isNaN(this.formDistance)
    },
    editable: function () {
      return true
    },
    progress: function () {
      if (!this.report) {
        return null
      }
      return this.report[this.type]
    }
  }


}
</script>

<style scoped lang="scss">

@import "src/styles/theme.scss";

.swim {
  background: $swim;
  height: 4pt;
}


.bike {
  background: $bike;
  height: 4pt;
}

.run {
  background: $run;
  height: 4pt;
}
</style>
