





























































































import Vue from "vue";
import Login from "@/views/Login.vue";

export default Vue.extend({
  name: "App",

  components: {Login},

  data: () => ({

    drawer: null
  }),

  computed: {
    user: function () {
      return this.$store.getters.user
    },
  }

});
