var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":!_vm.months}},[_c('v-card-title',[_vm._v("Training calendar "),_c('v-spacer'),(_vm.athlet)?_c('span',[_vm._v(" Start date: "+_vm._s(_vm.athlet.start_date)+" ")]):_vm._e()],1),(_vm.months)?_c('v-container',{staticClass:"d-flex flex-row flex-wrap justify-content-start card-body no-gutters",attrs:{"fluid":""}},_vm._l((_vm.months),function(month){return _c('v-sheet',{key:month.id,staticClass:"mr-3 mb-3 text-center no-select",attrs:{"elevation":"1","tile":""}},[_c('h6',{staticClass:"float no-select"},[_vm._v(_vm._s(_vm._f("moment")(month.month,"MMMM YYYY")))]),_c('table',{staticClass:"calendar p-22 text-center"},[_c('thead',[_c('tr',[_c('td',[_vm._v("Mon")]),_c('td',[_vm._v("Tue")]),_c('td',[_vm._v("Wed")]),_c('td',[_vm._v("Thu")]),_c('td',[_vm._v("Fri")]),_c('td',[_vm._v("Sat")]),_c('td',[_vm._v("Sun")])])]),_vm._l((month.weeks),function(week){return _c('tr',{key:week.id},_vm._l((week),function(day){return _c('td',{key:day.id},[_c('div',{staticClass:"no-select",class:{selected: day.date == _vm.date,
                      muted: day.mute  ,
                      notraning: !day.mute && !day.id,
                    ok: day.status == 1,
                    today: (new Date(day.date)).setHours(0,0,0,0) == (new Date()).setHours(0,0,0,0),
                    auchtung: day.status == 2,
                    no_report: day.status == 3,
                    },staticStyle:{"display":"table-cell"},on:{"click":function($event){return _vm.selectDay(day)}}},[_vm._v(_vm._s(_vm._f("moment")(day.date,"D"))+" ")])])}),0)})],2)])}),1):_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"article"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }