<template>

  <div>

    <v-skeleton-loader v-if="!athlet"
                       ref="skeleton"
                       type="article"
                       class="mx-auto"
    />


    <div class="row no-gutters">
      <AthletCard class="col-12" v-if="athlet" :athlet="athlet" @refresh="refresh"/>
    </div>
    <OverviewContainer class="my-4 overview" v-if="athlet" :athlet="athlet" @dayselected="daySelected" ref="overviews"/>
    <TrainingCalendar v-if="athlet" :athlet="athlet" @daySelected="dateSelected" :date="this.date" class="my-4" ref="calendar"/>
    <TrainingReport v-if="this.date && this.athlet" :athlet="athlet" :date="this.date" ref="report"/>
  </div>
</template>


<script>
import OverviewContainer from "@/components/charts/OverviewContainer";
import TrainingCalendar from "@/components/TrainingCalendar";

import TrainingReport from "@/components/Workout";
import AthletCard from "@/components/AthletCard";


export default {
  components: {
    TrainingCalendar,
    OverviewContainer,
    TrainingReport,
    AthletCard,
  },

  data: () => {
    return {
      athlet: null
    }
  },


  props: ["id", "date"],

  mounted() {

    this.$http.get(`/athlets/${this.id}/`).then(
        (resp) => {
          this.athlet = resp.data
        }
    );


  },

  methods: {

    daySelected: function (day) {
      this.$router.push({name: 'progress', params: {id: this.id, date: day}})
    },

    dateSelected: function (day) {
      this.$router.push({name: 'progress', params: {id: this.id, date: day.date}})
    },
    refresh: function () {
      this.$refs.overviews.fetchData();
      this.$refs.calendar.fetchData();
      this.$refs.report.fetchData();
    }
  }
};
</script>

<style scoped>
.overview {
  height: 80px;
}

.strava {
  height: 180px;
}
</style>
