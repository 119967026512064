<template>
  <v-container fluid class="pa-0">
    <v-row>


      <v-col>
        <v-card :loading="loading">
          <v-card-subtitle>Week</v-card-subtitle>
          <Overview :chart-data="weekData" :options="options" style="height:140px"/>
        </v-card>
      </v-col>

      <v-col>
        <v-card :loading="loading">
          <v-card-subtitle>Month</v-card-subtitle>
          <Overview :chart-data="montData" :options="options" style="height:140px"/>

        </v-card>
      </v-col>

      <v-col>
        <v-card :loading="loading">
          <v-card-subtitle>Month</v-card-subtitle>
          <Overview :chart-data="yearData" :options="options" style="height:140px"/>
        </v-card>
      </v-col>
    </v-row>

  </v-container>

</template>
<script>
import ChartBase from "@/components/charts/ChartBase";
import Overview from "@/components/charts/Overview";
import moment from "moment";

export default {

  name: "ProgramOverview",
  components: {Overview},

  data() {
    return {
      plan: null,
      loading: false,
      options: {
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }


              label += `${moment.duration(tooltipItem.yLabel, 'hours').hours()}:${moment.duration(tooltipItem.yLabel, 'hours').minutes().toString().padStart(2, "0")}h`
              return label;
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            ticks: {
              callback: function (label) {

                label = `${moment.duration(label, 'hours').hours()}:${moment.duration(label, 'hours').minutes().toString().padStart(2, "0")}h`
                return label;
              }
            },
            stacked: true
          }]
        }
      }
    }
  },

  props: {
    pk: Number
  },

  async mounted() {
    this.loading = true
    const result = await this.$http.get(`/athlets/${this.pk}/appreview/`)
    this.plan = result.data
    this.loading = false
  },

  computed: {
    dayData: () => {

    },

    weekData: function () {
      if (!this.plan) {
        return {}
      }
      let sub = this.plan.week;
      return {
        labels: sub.map((val, _) => val.date),
        datasets: [
          {
            label: "swim",
            backgroundColor: "deepskyblue",
            data: sub.map((val, _) => val.swim)
          },
          {
            label: "bike",
            backgroundColor: "#fe2d88",
            data: sub.map((val, _) => val.bike)
          },
          {
            label: "run",
            backgroundColor: "darkgray",
            data: sub.map((val, _) => val.run)
          }
        ]
      };
    },
    montData: function () {
      if (!this.plan) {
        return {}
      }
      let sub = this.plan.month;
      return {
        labels: sub.map((val, i) => i+1),
        datasets: [
          {
            label: "swim",
            backgroundColor: "deepskyblue",
            data: sub.map((val, _) => val.swim)
          },
          {
            label: "bike",
            backgroundColor: "#fe2d88",
            data: sub.map((val, _) => val.bike)
          },
          {
            label: "run",
            backgroundColor: "darkgray",
            data: sub.map((val, _) => val.run)
          }
        ]
      };
    },

    yearData: function () {
      if (!this.plan) {
        return {}
      }
      let sub = this.plan.halfyear;
      return {
        labels: sub.map((val, i) => i+1),
        datasets: [
          {
            label: "swim",
            backgroundColor: "deepskyblue",
            data: sub.map((val, _) => val.swim)
          },
          {
            label: "bike",
            backgroundColor: "#fe2d88",
            data: sub.map((val, _) => val.bike)
          },
          {
            label: "run",
            backgroundColor: "darkgray",
            data: sub.map((val, _) => val.run)
          }
        ]
      };
    }


  }


}
</script>
<style>

</style>
