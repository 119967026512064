<template>
  <v-card :loading="loading">
    <v-card-title>
      <v-container fluid>
        <v-row>
          <v-col class="col-2">
            <v-menu
                v-model="menu1"
                ref="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="registrationDateRange"
                    label="Registration date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="registrationDateRange"
                  range
                  :first-day-of-week="1"
              >

                <v-btn
                    text
                    color="primary"
                    @click="registrationDateRange=[]"
                >
                  Clear
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn
                    text
                    color="primary"
                    @click="menu1 = false"
                >
                  Close
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col class="col-2">
            <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="eventDateRange"
                    label="Event date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="eventDateRange"
                  range
                  :first-day-of-week="1"
                  @input="menu2 = false"
              >
                <v-btn
                    text
                    color="primary"
                    @click="eventDateRange=[]"
                >
                  Clear
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn
                    text
                    color="primary"
                    @click="menu2= false"
                >
                  Close
                </v-btn>
              </v-date-picker>
            </v-menu>

          </v-col>

          <v-col class="col-2">
            <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="lastReportDateRange"
                    label="Last report date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  :first-day-of-week="1"
                  v-model="lastReportDateRange"
                  range
                  @input="menu3 = false"
              >
                <v-btn
                    text
                    color="primary"
                    @click="lastReportDateRange=[]"
                >
                  Clear
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn
                    text
                    color="primary"
                    @click="menu3 = false"
                >
                  Close
                </v-btn>
              </v-date-picker>
            </v-menu>

          </v-col>

          <v-col class="col-1">

            <v-select
                v-model="statuses"
                :items="statusVariants"
                :menu-props="{ maxHeight: '400' }"
                label="Status"
                multiple
                persistent-hint
            ></v-select>
          </v-col>
          <v-col class="col-1">

            <v-select
                v-model="programs"
                :items="programVariants"
                :menu-props="{ maxHeight: '400' }"
                label="Plan"
                multiple
                persistent-hint
            ></v-select>
          </v-col>
          <v-col class="col-4">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @change="loadData"
                autofocus
                @click:append-outer="loadData"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-skeleton-loader v-if="loading"
                       ref="skeleton"
                       type="table"

                       class="mx-auto"
    />


    <v-data-table v-else
                  :items="data"
                  :headers="headers"
                  :loading="loading"
                  :options.sync="options"
                  :server-items-length="count"
                  :items-per-page="25"

                  :footer-props="{
                          disableItemsPerPage: true,
                          itemsPerPageOptions: [25]

      }"

                  @update:page="loadData"
                  @update:sort-by="loadData"
                  @update:sort-desc="loadData"

    >
      <template v-slot:item.user__email="{ item }">
        <router-link v-if="item.plan" :to="{name: 'profile', params: { id: item.id}}">
          {{ item.user__email }}
        </router-link>
        <span v-else class="text--secondary">{{ item.user__email }}</span>
      </template>

      <template v-slot:item.actions="{item }">
        <router-link v-if="item.plan" :to="{name: 'progress', params: { id: item.id}}" style="text-decoration: none">
          <v-btn icon>
            <v-icon> mdi-calendar</v-icon>
          </v-btn>
        </router-link>


        <router-link v-if="item.plan" :to="{name: 'strava', params: { pk: item.id}}" style="text-decoration: none">
          <v-btn icon>
            <v-icon> mdi-chart-areaspline-variant</v-icon>
          </v-btn>
        </router-link>
      </template>

      <template v-slot:item.has_unreplied_message="{item}">
        <router-link :to="{name: 'chat', params: { id: item.id}}" style="text-decoration: none">
          <v-btn icon>
            <v-icon v-if="item.has_unreplied_message" color="orange"> mdi-chat</v-icon>
            <v-icon v-else> mdi-chat</v-icon>
          </v-btn>
        </router-link>
      </template>


      <template v-slot:item.status="{item }">

        <v-chip v-if="item.status ==='active'" color="green accent-1">{{ item.status }}</v-chip>
        <v-chip v-else-if="item.status ==='new'" color="teal accent-2">{{ item.status }}</v-chip>
        <v-chip v-else-if="item.status ==='disabled'" color="orange accent-1">{{ item.status }}</v-chip>
        <v-chip v-else>{{ item.status }}</v-chip>
      </template>
    </v-data-table>


  </v-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";

import * as lodash from 'lodash'
import * as moment from 'moment';

export default {
  data: function () {
    return {
      data: [],
      count: 0,
      search: "",
      loading: true,
      options: {
        itemsPerPage: 25
      },
      registrationDateRange: [],
      eventDateRange: [],
      lastReportDateRange: [],
      pagination: {},
      headers: [
        {text: "Email", value: "user__email"},
        {text: "Join date", value: "user__date_joined"},
        {text: "Plan", value: "plan.name"},
        {text: "Status", value: "status"},
        {text: "Last report", value: "last_report"},
        {text: "Start date", value: "start_date"},
        {text: "Chat", value: "has_unreplied_message"},
        {text: "Actions", value: "actions", sortable: false},

      ],

      menu1: "",
      menu2: "",
      menu3: "",
      programs: [],
      statuses: [],
      programVariants: ["full", "half", "sprint", "olympic"],
      statusVariants: [{text: "active", value: 1}, {text: "new", value: 0}, {text: "disabled", value: -1},],
      dialog: false,
      dialogDelete: false,
    };
  },


  mounted() {
    this.loadData();
  },


  filters: {
    dateFormat: function (val) {
      return val
    }
  },

  // watch: {
  //     search: {
  //         handler(val) {
  //             if(val.length >=3) {
  //                 var self = this;
  //                 lodash.debounce(function (v) {
  //                     self.loadData();
  //                 }, 500)();
  //
  //             }
  //         }
  //     }
  // },

  methods: {


    toStatus: it => {
      if (it === 0) return "new";
      else if (it === 1) return "active";
      else if (it === -1) return "disabled";
      return "undefined";
    },


    loadData: function () {
      const {sortBy, sortDesc, page, itemsPerPage} = this.options
      this.loading = true;
      this.$http.get("athlets/", {
        params: {
          page: page,
          search: this.search,
          page_size: itemsPerPage,
          ordering: sortBy ? (sortDesc[0] ? "-" + sortBy[0] : sortBy[0]) : null,
          plan: this.programs ? this.programs.join(",") : undefined,
          status: this.statuses ? this.statuses.join(",") : undefined,
          joined_after: this.registrationDateRange.length > 0 ? this.registrationDateRange[0] : undefined,
          joined_before: this.registrationDateRange.length > 0 ? this.registrationDateRange[this.registrationDateRange.length - 1] : undefined,
          start_date_after: this.eventDateRange.length > 0 ? this.eventDateRange[0] : undefined,
          start_date_before: this.eventDateRange.length > 0 ? this.eventDateRange[this.eventDateRange.length - 1] : undefined,
          last_report_after: this.lastReportDateRange.length > 0 ? this.lastReportDateRange[0] : undefined,
          last_report_before: this.lastReportDateRange.length > 0 ? this.lastReportDateRange[this.lastReportDateRange.length - 1] : undefined
        }
      }).then(
          (response) => {
            this.loading = false;

            this.count = response.data.count;
            this.data = response.data.results
                .map(it => {
                  it.user__email = it.user.email;
                  it.user__date_joined = moment(it.user.date_joined).format("YYYY-MM-DD hh:mm");
                  it.status = this.toStatus(it.status);
                  if (it.has_unreplied_message === null) {
                    it.has_unreplied_message = false
                  } else if (it.has_unreplied_message === false) {
                    it.has_unreplied_message = true
                  } else if (it.has_unreplied_message === true) {
                    it.has_unreplied_message = false
                  }
                  it.last_report = it.last_report ? moment(it.last_report).format("YYYY-MM-DD hh:mm") : undefined;
                  it.start_date = it.start_date ? moment(it.start_date).format("YYYY-MM-DD") : undefined;
                  return it;
                })
          }
      )
      ;
    }
  },

  watch: {
    programs: function () {
      this.loadData()
    }
    ,
    statuses: function () {
      this.loadData()
    }
    ,
    registrationDateRange: function () {
      this.loadData()
    }
    ,
    eventDateRange: function () {
      this.loadData()
    }
    ,
    lastReportDateRange: function () {
      this.loadData()
    }

  }

}
;
</script>
