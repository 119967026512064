<template>

  <v-container fluid>

    <AthletCard class="mb-5" v-if="athlet" :athlet="athlet"/>
    <SwimChart :data="trainings.swim" :zones="athlet.zones"/>
    <BikeChart :data="trainings.bike" :zones="athlet.zones"/>
    <RunChart :data="trainings.run" :zones="athlet.zones"/>
  </v-container>

</template>

<script>
import BikeChart from "./charts/BikeChart";
import RunChart from "./charts/RunChart";
import SwimChart from "./charts/SwimChart";
import AthletCard from "@/components/AthletCard";

export default {
  name: "Strava.vue",
  components: {RunChart, BikeChart, SwimChart, AthletCard},
  data: function () {
    return {
      loading: false,
      athlet: {},
      trainings: {},
    }
  },


  props: {
    pk: String,
  },


  mounted: async function () {
    await this.fetchData()
  },

  methods: {
    fetchData: async function () {
      this.loading = true
      let result = await this.$http.get(`/athlets/${this.pk}/strava/stream/`)
      let athlet = await this.$http.get(`/athlets/${this.pk}/`)
      this.athlet = await athlet.data
      this.trainings = await result.data
      this.loading = false
    }
  },

}
</script>

<style scoped>

</style>
