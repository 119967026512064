<template>

  <div>

    <v-card :loading="loading">
      <v-toolbar>
        <v-toolbar-title>
          <span class="username font-weight-bold">{{ profile.user.email }}</span>&nbsp;
          <em class="plan">({{ profile.plan.name }})</em>&nbsp;
          <span
              class="status"
              :class="{
        new: profile.status == 0,
        active: profile.status == 1,
        disabled: profile.status == -1
        }"
          >{{ status }}</span>
        </v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn text :to="{name: 'progress', params: {id: profile.id}}">View progress
          </v-btn>
          <v-btn text :to="{name: 'chat', params: {id: profile.id}}">View messages
          </v-btn>

        </v-toolbar-items>

        <v-btn

            :disabled="profile.status == 1"
            @click="activate"
        >Activate
        </v-btn>

        <v-btn @click="send_test_message">Send test message</v-btn>

      </v-toolbar>

    </v-card>
    <v-card :loading="loading" class="my-4">


      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field readonly label="Joined" v-model="join_date"/>
          </v-col>

          <v-col>
            <v-text-field readonly label="Last report" v-model="last_report"/>
          </v-col>

          <v-col>
            <v-checkbox readonly label="Has strava" v-model="profile.has_strava"/>
          </v-col>

          <v-col/>
        </v-row>
        <v-row>
          <v-col>
            <v-select label="Swim class" :items="this.swim_items" v-model="profile.swim"/>
          </v-col>
          <v-col>
            <v-select label="Bike class" :items="this.bike_items" v-model="profile.bike"/>
          </v-col>
          <v-col>
            <v-select label="Run class" :items="this.run_items" v-model="profile.run"/>
          </v-col>
          <v-col>
            <v-text-field label="Free time" v-model="profile.freetime"/>
          </v-col>
        </v-row>

        <v-row>

          <v-col>
            <v-text-field label="Expirence in triathlon" v-model="profile.extras.has_experience"/>
          </v-col>

          <v-col>
            <v-text-field label="age" v-model="profile.extras.age"/>
          </v-col>
          <v-col>
            <v-text-field label="height" v-model="profile.extras.height"/>

          </v-col>
          <v-col>
            <v-text-field label="weight" v-model="profile.extras.weight"/>
          </v-col>
        </v-row>
        <v-row>

          <v-col>
            <v-text-field label="Scores" v-model="profile.scores"/>
          </v-col>

          <v-col>
            <v-radio-group row label="gender" v-model="profile.extras.gender">
              <v-radio label="male" value="m"></v-radio>
              <v-radio label="female" value="f"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col>
            <v-checkbox label="Diabetic" v-model="profile.extras.diabetic"/>
          </v-col>

          <v-col>
            <v-checkbox label="Vegetarian" v-model="profile.extras.vegetarian"/>
          </v-col>
        </v-row>

        <v-row>


          <v-col>
            <v-checkbox label="Disabled" v-model="profile.extras.handicapped"/>
          </v-col>
          <v-col>
            <v-checkbox label="Asthmatic" v-model="profile.extras.asthmatic"/>
          </v-col>
          <v-col>
            <v-checkbox label="Aged" v-model="profile.extras.aged"/>
          </v-col>
          <v-col/>
        </v-row>
      </v-card-text>

    </v-card>


    <div class="my-4 d-flex">
      <v-card class="mr-4">
        <v-card-subtitle>Run pace zones</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class="d-flex">
              <v-text-field label="A1 min" dense class="shrink  mx-4" v-model="profile.zones['run-a1-pace-min']"/>
              <v-text-field label="A1 max" dense class="shrink  mx-4" v-model="profile.zones['run-a1-pace-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L1 min" dense class="shrink  mx-4" v-model="profile.zones['run-l1-pace-min']"/>
              <v-text-field label="L1 max" dense class="shrink  mx-4" v-model="profile.zones['run-l1-pace-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L2 min" dense class="shrink  mx-4" v-model="profile.zones['run-l2-pace-min']"/>
              <v-text-field label="L2 max" dense class="shrink  mx-4" v-model="profile.zones['run-l2-pace-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L3 min" dense class="shrink  mx-4" v-model="profile.zones['run-l3-pace-min']"/>
              <v-text-field label="L3 max" dense class="shrink  mx-4" v-model="profile.zones['run-l3-pace-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L4 min" dense class="shrink  mx-4" v-model="profile.zones['run-l4-pace-min']"/>
              <v-text-field label="L4 max" dense class="shrink  mx-4" v-model="profile.zones['run-l4-pace-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L5 min" dense class="shrink  mx-4" v-model="profile.zones['run-l5-pace-min']"/>
              <v-text-field label="L5 max" dense class="shrink  mx-4" v-model="profile.zones['run-l5-pace-max']"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>


      <v-card class="mr-4">
        <v-card-subtitle>Swim pace zones</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class="d-flex">
              <v-text-field label="A1 min" dense class="shrink  mx-4" v-model="profile.zones['swim-a1-pace-min']"/>
              <v-text-field label="A1 max" dense class="shrink  mx-4" v-model="profile.zones['swim-a1-pace-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L1 min" dense class="shrink  mx-4" v-model="profile.zones['swim-l1-pace-min']"/>
              <v-text-field label="L1 max" dense class="shrink  mx-4" v-model="profile.zones['swim-l1-pace-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L2 min" dense class="shrink  mx-4" v-model="profile.zones['swim-l2-pace-min']"/>
              <v-text-field label="L2 max" dense class="shrink  mx-4" v-model="profile.zones['swim-l2-pace-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L3 min" dense class="shrink  mx-4" v-model="profile.zones['swim-l3-pace-min']"/>
              <v-text-field label="L3 max" dense class="shrink  mx-4" v-model="profile.zones['swim-l3-pace-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L4 min" dense class="shrink  mx-4" v-model="profile.zones['swim-l4-pace-min']"/>
              <v-text-field label="L4 max" dense class="shrink  mx-4" v-model="profile.zones['swim-l4-pace-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L5 min" dense class="shrink  mx-4" v-model="profile.zones['swim-l5-pace-min']"/>
              <v-text-field label="L5 max" dense class="shrink  mx-4" v-model="profile.zones['swim-l5-pace-max']"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>


      <v-card class="mr-4">
        <v-card-subtitle>Bike power zones</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class="d-flex">
              <v-text-field label="A1 min" dense class="shrink  mx-4" v-model="profile.zones['bike-a1-power-min']"/>
              <v-text-field label="A1 max" dense class="shrink  mx-4" v-model="profile.zones['bike-a1-power-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L1 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l1-power-min']"/>
              <v-text-field label="L1 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l1-power-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L2 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l2-power-min']"/>
              <v-text-field label="L2 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l2-power-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L3 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l3-power-min']"/>
              <v-text-field label="L3 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l3-power-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L4 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l4-power-min']"/>
              <v-text-field label="L4 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l4-power-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L5 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l5-power-min']"/>
              <v-text-field label="L5 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l5-power-max']"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>


      <v-card class="mr-4">
        <v-card-subtitle>Bike heartrate zones</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class="d-flex">
              <v-text-field label="A1 min" dense class="shrink  mx-4" v-model="profile.zones['bike-a1-heartrate-min']"/>
              <v-text-field label="A1 max" dense class="shrink  mx-4" v-model="profile.zones['bike-a1-heartrate-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L1 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l1-heartrate-min']"/>
              <v-text-field label="L1 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l1-heartrate-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L2 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l2-heartrate-min']"/>
              <v-text-field label="L2 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l2-heartrate-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L3 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l3-heartrate-min']"/>
              <v-text-field label="L3 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l3-heartrate-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L4 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l4-heartrate-min']"/>
              <v-text-field label="L4 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l4-heartrate-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L5 min" dense class="shrink  mx-4" v-model="profile.zones['bike-l5-heartrate-min']"/>
              <v-text-field label="L5 max" dense class="shrink  mx-4" v-model="profile.zones['bike-l5-heartrate-max']"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>


      <v-card>
        <v-card-subtitle>Run heartrate zones</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col class="d-flex">
              <v-text-field label="A1 min" dense class="shrink  mx-4" v-model="profile.zones['run-a1-heartrate-min']"/>
              <v-text-field label="A1 max" dense class="shrink  mx-4" v-model="profile.zones['run-a1-heartrate-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L1 min" dense class="shrink  mx-4" v-model="profile.zones['run-l1-heartrate-min']"/>
              <v-text-field label="L1 max" dense class="shrink  mx-4" v-model="profile.zones['run-l1-heartrate-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L2 min" dense class="shrink  mx-4" v-model="profile.zones['run-l2-heartrate-min']"/>
              <v-text-field label="L2 max" dense class="shrink  mx-4" v-model="profile.zones['run-l2-heartrate-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L3 min" dense class="shrink  mx-4" v-model="profile.zones['run-l3-heartrate-min']"/>
              <v-text-field label="L3 max" dense class="shrink  mx-4" v-model="profile.zones['run-l3-heartrate-max']"/>
            </v-col>
          </v-row>


          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L4 min" dense class="shrink  mx-4" v-model="profile.zones['run-l4-heartrate-min']"/>
              <v-text-field label="L4 max" dense class="shrink  mx-4" v-model="profile.zones['run-l4-heartrate-max']"/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <v-text-field label="L5 min" dense class="shrink  mx-4" v-model="profile.zones['run-l5-heartrate-min']"/>
              <v-text-field label="L5 max" dense class="shrink  mx-4" v-model="profile.zones['run-l5-heartrate-max']"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>


    <div class="my-4 d-flex">
      <v-spacer/>
      <v-btn disabled>Update</v-btn>
    </div>
    <ProgramOverview :pk="id" class="my-4"/>ё


    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Debug info:
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <pre>{{ profile }}</pre>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

import moment from 'moment';

import ProgramOverview from "@/components/ProgramOverview";

export default {
  components: {ProgramOverview},
  props: ["id"],

  data: function () {
    return {
      profile: {},
      swim_items: [
        {
          value: 1,
          text: "4’30” - 5’00”"
        },
        {
          value: 2,
          text: "5’00” - 5’30”"
        },
        {
          value: 3,
          text: "5’30” - 6’00”"
        },
        {
          value: 4,
          text: "6’00” - 6’30”"
        },
        {
          value: 5,
          text: "6’30” - 7’00”"
        },
        {
          value: 6,
          text: "7’00” +"
        },
      ],
      bike_items: [
        {
          value: 1,
          text: "48km/h – 40km/h"
        },
        {
          value: 2,
          text: "40km/h – 35km/h"
        },
        {
          value: 3,
          text: "35km/h – 30km/h"
        },
        {
          value: 4,
          text: "30km/h – 27km/h"
        },
        {
          value: 5,
          text: "7km/h – 24km/h"
        },
        {
          value: 6,
          text: "Less then 24km/h+"
        },
      ],
      run_items: [
        {
          value: 1,
          text: "3'00”-3'20” (pace)"
        },
        {
          value: 2,
          text: "3'20”-3'40” (pace)"
        },
        {
          value: 3,
          text: "3'40”-4'00” (pace)"
        },
        {
          value: 4,
          text: "4'00”-4'30” (pace)"
        },
        {
          value: 5,
          text: "4'30”-5'00” (pace)"
        },
        {
          value: 6,
          text: "5'00”+  (pace)"
        },
      ],
      loading: true,
    };
  },

  methods: {
    fetch: function () {
      this.loading = true;
      this.$http.get(`/athlets/${this.id}/`).then(response => {
        this.profile = response.data;
        this.loading = false;
      });
    },

    activate: function () {
      this.$http.post(`/athlets/${this.id}/activate/`).then(response => {
        this.fetch();
      });
    },

    send_test_message: function () {
      this.$http.post(`/athlets/${this.id}/sendpush/`).then(response => {
        this.fetch();
      });
    }
  },

  mounted: function () {
    this.fetch();
  },

  computed: {
    status: function () {
      if (this.profile.status != undefined) {
        switch (this.profile.status) {
          case 1:
            return "active";
          case -1:
            return "disabled";
          case 0:
            return "new";
        }
      }

      return "n/a";
    },

    join_date: function () {
      return moment(this.profile.user.date_joined).format("MM/DD/YY HH:mm")
    },

    last_report: function () {
      return moment(this.profile.last_report).format("MM/DD/YY HH:mm")
    }

  }
};
</script>

<style lang="scss" scoped>
.username {
  font-weight: bolder;
  font-size: 1.2em;
}

.plan {
  text-transform: uppercase;
}

.new {
  color: blue;
}

.active {
  color: black;
}

.disabled {
  color: orange;
}

.activate {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.extras {
  p {
    display: inline-block;
    padding: 8pt;
    margin: 8pt;
    margin-left: 8pt;

    border: dotted 1px gray;
    background: #ffffff;
    border-radius: 4pt;
  }
}
</style>
